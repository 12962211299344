import { Link } from 'react-router-dom';

import { Breadcrumb as AntBreadcrumb } from 'antd';

import Box from '@mui/material/Box';

import { useBreadcrumb } from './BreadcrumbContext';

export const Breadcrumb = () => {
    const { breadcrumbs } = useBreadcrumb();

    const breadcrumbItems = [
        {
            title: (
                <Link
                    to="/"
                    style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                    首頁
                </Link>
            ),
            key: 'home',
        },
        ...breadcrumbs.map(({ title, path }) => ({
            title: path ? (
                <Link
                    to={path}
                    style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                    {title}
                </Link>
            ) : (
                <span style={{ color: '#FFFFFF' }}>{title}</span>
            ),
            key: path || title,
        })),
    ];

    return (
        <Box sx={{ color: '#FFFFFF' }}>
            <AntBreadcrumb
                style={{
                    margin: 0,
                    padding: 0,
                    color: '#FFFFFF',
                    fontSize: '16px',
                    fontFamily: 'nunito',
                    fontWeight: '500',
                }}
                items={breadcrumbItems}
            />
        </Box>
    );
};
