import { useEffect } from 'react';

import { PanelGroup } from 'react-resizable-panels';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useBreadcrumb } from '../../../../components/layout/BreadcrumbContext';
import { Panel, ResizeHandle } from '../../../../components/resizeable';
import { withAuth } from '../../../../hocs';
import { useGetCourseDetailQuery, useGetProblemByIdQuery } from '../../../../store/services/restApi';
import { NoFooterTemplate } from '../../../../template/pages/with-hocs';

import { LeftPageContainer } from './LeftPage';
import { RightPageContainer } from './RightPage';

import './ProblemPage.css';

const ProblemPage = () => {
    const { problemId, courseId } = useParams();

    const { setBreadcrumbs } = useBreadcrumb();

    const {
        data: course = {},
    } = useGetCourseDetailQuery({ courseId });

    const {
        data: problem = {},
    } = useGetProblemByIdQuery({ problemId });

    useEffect(() => {
        const breadcrumbs = [
            {
                title: courseId ? '課程' : '題庫',
                path: courseId ? '/courses' : '/problems',
            },
            {
                title: course?.name || '',
                path: courseId ? `/courses/${courseId}` : '',
            },
            {
                title: `${problem?.title} (${problemId})` || '',
                path: courseId ? `/courses/${courseId}/problems/${problemId}/description` : `/problems/${problemId}/description`,
            },
        ].filter((breadcrumb) => breadcrumb.title);

        setBreadcrumbs(breadcrumbs);
    }, [course?.name, courseId, problem?.title, problemId]);

    const content = (
        <PanelGroup
            direction="horizontal"
            style={{
                height: 'calc(100vh - 80px)',
            }}
        >
            <Panel
                collapsible
                minSize={30}
                defaultSize={33.33}
            >
                <LeftPageContainer />
            </Panel>
            <ResizeHandle
                style={{
                    backgroundColor: '#e0e0e0',
                }}
                direction="horizontal"
            />
            <Panel
                collapsible
                minSize={25}
                defaultSize={66.67}
            >
                <Box>
                    <RightPageContainer />
                </Box>
            </Panel>
        </PanelGroup>
    );

    return (
        <NoFooterTemplate content={content} />
    );
};

export default withAuth(ProblemPage);
