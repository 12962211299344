import { useNavigate, useParams } from 'react-router-dom';

import { Tabs } from 'antd';

import Typography from '@mui/material/Typography';

import {
    DescriptionContainer,
    DiscussionContainer,
    RecordContainer,
} from '../../containers';

const LEFT_PAGE_OPTIONS = {
    DESCRIPTION: 'description',
    DISSCUSSION: 'discussion',
    // SOLUTION: 'solution',
    RECORD: 'record',
};

const TABS_ZH_LABELS = {
    [LEFT_PAGE_OPTIONS.DESCRIPTION]: '描述',
    [LEFT_PAGE_OPTIONS.DISSCUSSION]: '討論',
    [LEFT_PAGE_OPTIONS.SOLUTION]: '解答',
    [LEFT_PAGE_OPTIONS.RECORD]: '紀錄',
};

const tabItems = Object.values(LEFT_PAGE_OPTIONS).map((label) => ({
    key: label,
    label: (
        <Typography
            variant="h6"
            textTransform="capitalize"
            fontWeight="bold"
            margin="0 24px"
        >
            {TABS_ZH_LABELS[label]}
        </Typography>
    ),
    children: null,
}));

const getLeftPage = (id, type) => {
    const { courseId } = useParams();
    console.log('courseId', courseId);
    switch (type) {
        case LEFT_PAGE_OPTIONS.DISSCUSSION:
            return <DiscussionContainer id={id} courseId={courseId} />;
        // case LEFT_PAGE_OPTIONS.SOLUTION:
        //     return <h1>解答</h1>;
        case LEFT_PAGE_OPTIONS.RECORD:
            return <RecordContainer id={id} courseId={courseId} />;
        default:
            return <DescriptionContainer />;
    }
};

export const LeftPageContainer = () => {
    const { problemId, left, right, dataId, courseId } = useParams();
    const navigate = useNavigate();

    return (
        <>
            <Tabs
                activeKey={left}
                items={tabItems}
                onChange={(page) => {
                    let url = courseId
                        ? `/courses/${courseId}/problems/${problemId}/${page}`
                        : `/problems/${problemId}/${page}`;
                    if (right && dataId) {
                        url += `/${right}/${dataId}`;
                    }
                    navigate(url);
                }}
            />
            {getLeftPage(problemId, left)}
        </>
    );
};
