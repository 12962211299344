import { AcTag } from './AcTag';
import { AutoTag } from './AutoTag';
import { CeTag } from './CeTag';
import { ErrorTag } from './ErrorTag';
import { HasSubmitTag } from './HasSubmitTag';
import { ManualTag } from './ManualTag';
import { PaTag } from './PaTag';
import { ReTag } from './ReTag';
import { SuccessTag } from './SuccessTag';
import { TleTag } from './TleTag';
import { WaTag } from './WaTag';

import './tag.css';

export class TagFactory {
    static get(type, passedCount, totalCount) {
        switch (type) {
            case 'AC':
                return <AcTag />;
            case 'CE':
                return <CeTag />;
            case 'PA':
                return <PaTag passedCount={passedCount} totalCount={totalCount} />;
            case 'RE':
                return <ReTag />;
            case 'TLE':
                return <TleTag />;
            case 'WA':
                return <WaTag />;
            case 'SUCCESS':
                return <SuccessTag />;
            case 'ERROR':
                return <ErrorTag />;
            case 'AUTO':
                return <AutoTag />;
            case 'MANUAL':
                return <ManualTag />;
            case 'HAS_SUBMIT':
                return <HasSubmitTag />;
            default:
                return null;
        }
    }
}
