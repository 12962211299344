import { useNavigate, useParams } from 'react-router-dom';

import { Avatar, Button, List } from 'antd';

import CommentIcon from '@mui/icons-material/Comment';

import { useGetAllArticlesByProblemQuery } from '../../../store/services/restApi';

export const ArticleListContainer = () => {
    const { problemId, courseId } = useParams();
    const navigate = useNavigate();

    const {
        data: allArticles = [],
        isLoading: allArticlesAreFetching,
    } = useGetAllArticlesByProblemQuery({ problemId });

    return (
        <List
            itemLayout="horizontal"
            dataSource={allArticles}
            loading={allArticlesAreFetching}
            renderItem={(item) => {
                const articleUrl = `${courseId ? `/courses/${courseId}/problems/${problemId}/discussion` : `/problems/${problemId}/discussion`}/article/${item.id}`;
                const navigateToArticle = () => {
                    navigate(articleUrl);
                };

                return (
                    <List.Item
                        actions={[
                            <CommentIcon />,
                        ]}
                        onClick={navigateToArticle}
                    >
                        <List.Item.Meta
                            avatar={<Avatar src={item.owner.avatar} />}
                            title={(
                                <Button
                                    type="link"
                                    onClick={navigateToArticle}
                                >
                                    {item.title}
                                </Button>
                            )}
                            description={item.content}
                        />
                    </List.Item>
                );
            }}
        />
    );
};
