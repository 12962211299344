import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import {
    Tabs,
    Typography,
} from 'antd';

import { useBreadcrumb } from '../../../components/layout/BreadcrumbContext';
import {
    useGetAllAssignmentsByCourseQuery,
    useGetCourseDetailQuery,
} from '../../../store/services/restApi';
import { DateFormatter } from '../../../utils/formatter';

import { AssignmentProblemTableContainer } from './AssignmentProblemTableContainer';

const { Paragraph } = Typography;

export const AssignmentTabsContainer = () => {
    const { courseId } = useParams();

    const {
        data: courseDetail = {},
        isSuccess: courseDetailIsFetched,
    } = useGetCourseDetailQuery({ courseId });

    const {
        data: allAssignments = [],
        refetch: refetchAssignments,
    } = useGetAllAssignmentsByCourseQuery({ courseId });

    const { setBreadcrumbs } = useBreadcrumb();

    useEffect(() => {
        refetchAssignments({ courseId });
    }, [courseId]);

    useEffect(() => {
        if (courseDetailIsFetched) {
            setBreadcrumbs([
                {
                    title: '課程',
                    path: '/courses',
                },
                {
                    title: courseDetail?.name || '',
                    path: `/courses/${courseId}`,
                },
            ]);
        }
    }, [courseDetailIsFetched, courseDetail?.name]);

    if (!courseDetailIsFetched) {
        return <> </>;
    }

    const tabsItems = allAssignments.map((assignment) => ({
        key: assignment.id,
        label: assignment.title,
        children: assignment.isActive
            ? (
                <>
                    <Paragraph
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {assignment.description}
                    </Paragraph>
                    <Paragraph>
                        到期日: {DateFormatter.datetime(assignment.endDate)}
                    </Paragraph>
                    <AssignmentProblemTableContainer
                        assignmentId={assignment.id}
                        courseId={courseId}
                    />
                </>
            )
            : (<> </>),
        disabled: !assignment.isActive,
    }));

    return (
        <Tabs
            defaultActiveKey={allAssignments[0]?.id}
            items={tabsItems}
        />
    );
};
