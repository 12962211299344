import { useEffect, useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';

import { Form, message as antdMessage } from 'antd';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Input } from '../../../components/input';
import { useBreadcrumb } from '../../../components/layout/BreadcrumbContext';
import { AlertWindow } from '../../../components/window';
import { ALERT_TYPE } from '../../../constants/alert';
import ROUTES from '../../../router/routes';
import { useRegisterMutation } from '../../../store/services/restApi';

const getMessage = (error) => {
    const msgObj = error?.data || {};
    return Object.values(msgObj).join('、');
};

export const RegisterContainer = () => {
    const [messageApi, contextHolder] = antdMessage.useMessage();
    const navigate = useNavigate();

    const [
        register,
        {
            error: registerError,
            isLoading: registering,
            isSuccess: registered,
            isError: registerFailed,
        },
    ] = useRegisterMutation();

    const loading = registering;
    const showMessage = registerFailed;
    const message = getMessage(registerError);
    const [showAlert, setShowAlert] = useState(false);

    const error = () => {
        messageApi.open({
            type: 'error',
            content: '密碼不一致',
        });
    };

    const { setBreadcrumbs } = useBreadcrumb();

    useEffect(() => {
        setBreadcrumbs([
            {
                title: '註冊',
                path: '/register',
            },
        ]);
    }, []);

    useEffect(() => {
        if (registered) {
            setShowAlert(true);
        }
        else {
            setShowAlert(false);
        }
    }, [registered]);

    const handleRegister = ({ email, username, password, repeatPassword }) => {
        if (password !== repeatPassword) {
            error();
        }
        else {
            register({ email, username, password });
        }
    };

    return (
        <>
            {contextHolder}
            <Form onFinish={handleRegister}>
                <Form.Item name="username">
                    <Input label="使用者帳號" required />
                </Form.Item>
                <Form.Item name="email">
                    <Input label="Email" required />
                </Form.Item>
                <Form.Item name="password">
                    <Input label="密碼" password required />
                </Form.Item>
                <Form.Item name="repeatPassword">
                    <Input label="密碼確認" password required />
                </Form.Item>
                {
                    showMessage
                        ? (
                            <Box margin="16px 0" flex={1}>
                                <Alert severity="error">{message}</Alert>
                            </Box>
                        )
                        : <Box flex={1} />
                }
                <Box textAlign="center" flex={1}>
                    <LoadingButton loading={loading} variant="contained" type="submit">
                        <Typography>註冊</Typography>
                    </LoadingButton>
                </Box>
            </Form>

            <AlertWindow
                type={ALERT_TYPE.SUCCESS.type}
                message="註冊成功！"
                visible={showAlert}
                onOk={() => {
                    setShowAlert(false);
                    navigate(ROUTES.LOGIN.link);
                }}
                onCancel={() => {
                    setShowAlert(false);
                    navigate(ROUTES.LOGIN.link);
                }}
            />
        </>
    );
};
