import { useState } from 'react';

import { Link } from 'react-router-dom';

import { Space } from 'antd';
import {
    CaretDownOutlined,
    CaretUpOutlined,
} from '@ant-design/icons';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';

export const UserMenu = ({ items, username }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                onClick={handleMenu}
            >
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    style={{ padding: '0' }}
                >
                    <AccountCircle />
                </IconButton>
                <Space>
                    <Typography>{username}</Typography>
                    {anchorEl ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </Space>

            </Box>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{
                    marginTop: '40px',
                }}
            >
                <MenuItem
                    key="題庫"
                    onClick={handleClose}
                    component={Link}
                    to="/problems"
                >
                    題庫
                </MenuItem>
                {items.map((item) => (
                    <MenuItem
                        key={item.label}
                        onClick={handleClose}
                        component={Link}
                        to={item.link}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
