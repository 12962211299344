import { useEffect } from 'react';

import {
    Button,
    Rate,
    Space,
    Table,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { useBreadcrumb } from '../../../components/layout/BreadcrumbContext';
import { TagFactory } from '../../../components/tag';
import {
    useDeleteProblemMutation,
    useGetMyProblemsQuery,
} from '../../../store/services/restApi';

import { UpdateProblemContainer } from './UpdateProblemContainer';

const columns = [
    {
        title: '題目',
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        width: '30%',
    },
    {
        title: '類型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: '10%',
        render: (type) => {
            const typeText = type.toUpperCase() === 'A' ? 'AUTO' : 'MANUAL';
            return TagFactory.get(typeText);
        },
    },
    {
        title: '難度',
        dataIndex: 'level',
        key: 'level',
        align: 'center',
        width: '20%',
        render: (level) => (
            <Rate
                disabled
                defaultValue={level}
            />
        ),
    },
    {
        title: '通過率',
        dataIndex: 'acRate',
        key: 'acRate',
        align: 'center',
        width: '10%',
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: '30%',
        render: (_, record) => (
            <Space>
                <UpdateProblemContainer problemId={record.id} />
                <Button
                    type="primary"
                    danger
                    size="small"
                    onClick={() => record.onDelete()}
                >
                    <DeleteOutlined />
                </Button>
            </Space>
        ),
    },
];

export const OwnedProblemListContainer = () => {
    const {
        data: myProblems = [],
        isLoading: myProblemsAreFetching,
    } = useGetMyProblemsQuery();
    const [deleteProblem] = useDeleteProblemMutation();

    const handleDelete = (problemId) => {
        deleteProblem({ problemId });
    };

    const problemsData = myProblems.map((problem) => ({
        ...problem,
        onDelete: () => handleDelete(problem.id),
    }));

    const { setBreadcrumbs } = useBreadcrumb();

    useEffect(() => {
        setBreadcrumbs([
            {
                title: '我的題目',
                path: '/my-problems',
            },
        ]);
    }, []);

    return (
        <Table
            columns={columns}
            dataSource={problemsData}
            loading={myProblemsAreFetching}
        />
    );
};
