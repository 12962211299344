import { Button, Typography } from '@mui/material';

import openeduIcon from '../../assets/openeduIcon.png';
import { useGetAuthorizeUrlQuery } from '../../store/services/restApi';

export const OpenEduLoginButton = ({ color }) => {
    const {
        data: { authorizeUrl } = {},
    } = useGetAuthorizeUrlQuery();

    const openeduSvgIcon = (
        <img alt="openedu-icon" src={openeduIcon} height={16} width={16} />
    );

    return (
        <Button
            variant="outlined"
            color={color}
            type="button"
            startIcon={openeduSvgIcon}
            onClick={() => {
                window.location.href = authorizeUrl;
            }}
        >
            <Typography
                sx={{ fontWeight: '700' }}
            >
                OpenEdu 登入
            </Typography>
        </Button>
    );
};
