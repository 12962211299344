import { useNavigate } from 'react-router-dom';

import { Table } from 'antd';

import { TagFactory } from '../../../components/tag';
import ROUTES from '../../../router/routes';
import { useGetUserSubmissionRecordQuery } from '../../../store/services/restApi';
import { DateFormatter } from '../../../utils/formatter';

const columns = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        align: 'center',
    },
    {
        title: '狀態',
        dataIndex: 'status',
        key: 'status',
        width: '30%',
        align: 'center',
        render: (status) => TagFactory.get(status),
    },
    {
        title: '程式語言',
        dataIndex: 'language',
        key: 'language',
        width: '30%',
        align: 'center',
    },
    {
        title: '提交時間',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: '30%',
        align: 'center',
        render: (timestamp) => DateFormatter.datetime(timestamp),
    },
];

export const SubmissionListContainer = ({ id, courseId }) => {
    const navigate = useNavigate();

    const {
        data: userSubmissions = [],
    } = useGetUserSubmissionRecordQuery({ problemId: id });

    const submissionTableData = userSubmissions
        .map((submission) => ({
            ...submission,
            key: submission.id,
        })).sort((a, b) => {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);
            return dateB - dateA;
        });

    return (
        <Table
            columns={columns}
            dataSource={submissionTableData}
            onRow={(record) => ({
                onClick: () => {
                    const url = courseId
                        ? `${ROUTES.COURSES.link}/${courseId}/problems/${id}/record/report/${record.id}`
                        : `${ROUTES.PROBLEMS.link}/${id}/record/report/${record.id}`;
                    navigate(url);
                },
            })}
        />
    );
};
