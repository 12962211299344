import { Link } from 'react-router-dom';

import {
    Rate,
    Table,
} from 'antd';

import { TagFactory } from '../../../components/tag/factories';
import { useGetProblemStatsByAssignmentQuery } from '../../../store/services/restApi';
import { round } from '../../../utils/math';

export const AssignmentProblemTableContainer = ({ assignmentId, courseId }) => {
    const {
        data: assignmentProblems = [],
        isLoading: assignmentProblemsIsFetching,
    } = useGetProblemStatsByAssignmentQuery({ assignmentId });

    const columns = [
        {
            title: '狀態',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            align: 'center',
            render: (status) => TagFactory.get(status),
        },
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            align: 'center',
        },
        {
            title: '題目',
            dataIndex: 'title',
            key: 'title',
            width: '40%',
            render: (title, record) => (
                <Link to={`/courses/${courseId}/problems/${record.id}`}>{title}</Link>
            ),
        },
        {
            title: '難度',
            dataIndex: 'level',
            key: 'level',
            width: '20%',
            align: 'center',
            render: (level) => (
                <Rate disabled value={level} />
            ),
        },
        {
            title: 'AC率',
            dataIndex: 'acRate',
            key: 'acRate',
            width: '10%',
            align: 'center',
            render: (acRate) => `${round(acRate * 100, 2)}%`,
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={assignmentProblems.map((problem) => ({
                key: problem.id,
                ...problem,
            }))}
            loading={assignmentProblemsIsFetching}
        />
    );
};
