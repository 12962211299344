import { useParams } from 'react-router-dom';

import {
    ArticleContainer,
    EditorContainer,
    ReportContainer,
} from '../../containers';

const RIGHT_PAGE_OPTIONS = {
    EDITOR: 'editor',
    REPORT: 'report',
    ARTICLE: 'article',
};

const getRightPage = (problemId, type, dataId, courseId) => {
    switch (type) {
        case RIGHT_PAGE_OPTIONS.REPORT:
            return <ReportContainer id={dataId} courseId={courseId} />;
        case RIGHT_PAGE_OPTIONS.ARTICLE:
            return <ArticleContainer id={dataId} courseId={courseId} />;
        default:
            return (
                <EditorContainer id={problemId} courseId={courseId} />
            );
    }
};

export const RightPageContainer = () => {
    const { problemId, right, dataId, courseId } = useParams();

    return getRightPage(problemId, right, dataId, courseId);
};
