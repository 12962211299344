import { useRef, useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { PanelGroup } from 'react-resizable-panels';

import { Button, Drawer, Select } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { Panel, ResizeHandle } from '../../../../components/resizeable';
import { DefaultCodeAlertWindow } from '../../../../components/window/DefaultCodeAlertWindow';
import { useGetProblemByIdQuery } from '../../../../store/services/restApi';
import {
    selectLanguage,
    setLanguage,
} from '../../problemSlice';

import { BarContainer } from './BarContainer';
import { CodeEditorContainer } from './CodeEditorContainer';
import { ConsoleContainer } from './ConsoleContainer';
import { ProblemAiAssistant } from './ProblemAiAssistant';

const languageOptions = [
    {
        value: 'python',
        label: 'Python',
    },
];

export const EditorContainer = ({ id }) => {
    const panelRef = useRef(null);
    const editorRef = useRef(null);

    const {
        data: problem = {},
    } = useGetProblemByIdQuery({ problemId: id });

    const dispatch = useDispatch();
    const language = useSelector(selectLanguage);

    const [consoleVisible, setConsoleVisible] = useState(true);
    const [defaultCodeAlert, setDefaultCodeAlert] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };
    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleSelectChange = (value) => {
        dispatch(setLanguage(value));
    };

    const collapseConsole = () => {
        const consolePanel = panelRef.current;
        consolePanel.collapse();
        setConsoleVisible(false);
    };

    const expandConsole = () => {
        const consolePanel = panelRef.current;
        consolePanel.expand();
        setConsoleVisible(true);
    };

    return (
        <>
            <Box
                sx={{
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    paddingLeft: '10px',
                }}
            >
                <Box>
                    <Typography>
                        Language
                    </Typography>
                    <Select
                        showSearch
                        placeholder="選擇語言"
                        defaultValue={language}
                        onChange={handleSelectChange}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={languageOptions}
                        style={{ width: '120px' }}
                    />
                </Box>
                <Box>
                    <Button
                        style={{ marginRight: '10px' }}
                        onClick={handleDrawerOpen}
                    >
                        AI 助教
                    </Button>
                    <Button
                        style={{ marginRight: '10px' }}
                        onClick={() => setDefaultCodeAlert(true)}
                    >
                        <EyeOutlined />
                        查看預設程式碼
                    </Button>
                </Box>

            </Box>
            <Card
                sx={{
                    height: 'calc(100vh - 155px)',
                    overflowY: 'auto',
                    boxShadow: 'none',
                }}
            >
                <PanelGroup
                    direction="vertical"
                    style={{
                        height: 'calc(100vh - 155px - 50px)',
                    }}
                    units="pixels"
                >
                    <Panel collapsible>
                        <CodeEditorContainer
                            id={id}
                            language={language}
                            ref={editorRef}
                        />
                    </Panel>
                    <ResizeHandle
                        style={{
                            backgroundColor: '#e0e0e0',
                        }}
                    />
                    <Panel
                        ref={panelRef}
                        collapsible
                        minSize={200}
                        defaultSize={200}
                        onCollapse={(isCollapse) => {
                            setConsoleVisible(!isCollapse);
                        }}
                    >
                        <ConsoleContainer visible={consoleVisible} />
                    </Panel>
                </PanelGroup>
                <BarContainer
                    id={id}
                    handleOpen={() => {
                        if (consoleVisible) {
                            collapseConsole();
                        }
                        else {
                            expandConsole();
                        }
                    }}
                    isExpanded={consoleVisible}
                />
            </Card>
            { defaultCodeAlert && (
                <DefaultCodeAlertWindow
                    title="預設程式碼"
                    visible={defaultCodeAlert}
                    message={problem.defaultCode}
                    language="python"
                    okText={(
                        <CopyToClipboard text={problem.defaultCode}>
                            <Typography style={{ fontSize: '14px' }}>
                                複製到剪貼簿
                            </Typography>
                        </CopyToClipboard>
                    )}
                    cancelText="關閉"
                    onOk={() => {
                        setDefaultCodeAlert(false);
                    }}
                    onCancel={() => {
                        setDefaultCodeAlert(false);
                    }}
                />
            )}
            <Drawer title="AI 助教" onClose={handleDrawerClose} open={openDrawer} width="45%">
                <ProblemAiAssistant problemId={parseInt(id, 10)} />
            </Drawer>
        </>
    );
};
