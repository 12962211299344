import { useNavigate } from 'react-router-dom';

import { Table } from 'antd';

import { TagFactory } from '../../../components/tag/factories';
import ROUTES from '../../../router/routes';
import { useGetUserExecutionRecordQuery } from '../../../store/services/restApi';
import { DateFormatter } from '../../../utils/formatter';

const columns = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        align: 'center',
    },
    {
        title: '結果',
        dataIndex: 'success',
        key: 'success',
        width: '30%',
        align: 'center',
        render: (success) => TagFactory.get(success ? 'SUCCESS' : 'ERROR'),
    },
    {
        title: '程式語言',
        dataIndex: 'language',
        key: 'language',
        width: '30%',
        align: 'center',
    },
    {
        title: '測試時間',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: '30%',
        align: 'center',
        render: (timestamp) => DateFormatter.datetime(timestamp),
    },
];

export const ExecutionResultListContainer = ({ id, courseId }) => {
    const navigate = useNavigate();

    const {
        data: userExecutionResults = [],
    } = useGetUserExecutionRecordQuery({ problemId: id });

    const executionTableData = userExecutionResults
        .map((execution) => ({
            ...execution,
            key: execution.id,
        })).sort((a, b) => {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);
            return dateB - dateA;
        });

    return (
        <Table
            columns={columns}
            dataSource={executionTableData}
            onRow={(record) => ({
                onClick: () => {
                    const url = courseId
                        ? `${ROUTES.COURSES.link}/${courseId}/problems/${id}/record/report/${record.id}`
                        : `${ROUTES.PROBLEMS.link}/${id}/record/report/${record.id}`;
                    navigate(url);
                },
            })}
        />
    );
};
