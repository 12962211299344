import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { CourseListPage, CoursePage, MyCoursesPage } from '../features/course/pages';
import { ProblemListPage, ProblemPage } from '../features/problem/pages';
import { MyProblemPage } from '../features/problem/pages/my-problems';
import {
    AssignmentPage,
    CoursePage as TeacherCoursePage,
    CreateAssignmentPage,
} from '../features/teacher/pages';
import { LoginRegisterPage, LogoutPage } from '../features/user/pages';
import { CallbackPage } from '../features/user/pages/CallbackPage';

import ROUTES from './routes';

export const Router = () => (
    <BrowserRouter basename="/">
        <Routes>
            <Route
                exact
                path={ROUTES.HOME.link}
                element={<CourseListPage />}
            />
            <Route
                path={ROUTES.LOGIN.link}
                element={<LoginRegisterPage type="login" />}
            />
            <Route
                path={ROUTES.REGISTER.link}
                element={<LoginRegisterPage type="register" />}
            />
            <Route
                path={ROUTES.LOGOUT.link}
                element={<LogoutPage />}
            />
            <Route
                path={ROUTES.COURSES.link}
                element={<CourseListPage />}
            />
            <Route
                path={`${ROUTES.COURSES.link}/:courseId`}
                element={<CoursePage />}
            />
            <Route
                path={ROUTES.PROBLEMS.link}
                element={<ProblemListPage />}
            />
            <Route
                path={`${ROUTES.PROBLEMS.link}/:problemId/:left?/:right?/:dataId?`}
                element={<ProblemPage />}
            />
            <Route
                path={`${ROUTES.COURSES_COURSE_ID_PROBLEMS_PROBLEM_ID.link}/:problemId/:left?/:right?/:dataId?`}
                element={<ProblemPage />}
            />
            <Route
                path={ROUTES.MY_COURSES.link}
                element={<MyCoursesPage />}
            />
            <Route
                path={ROUTES.MY_PROBLEMS.link}
                element={<MyProblemPage />}
            />
            <Route
                path={ROUTES.TEACHER_CREATE_ASSIGNMENT.link}
                element={<CreateAssignmentPage />}
            />
            <Route
                path={ROUTES.TEACHER_COURSE.link}
                element={<TeacherCoursePage />}
            />
            <Route
                path={ROUTES.TEACHER_COURSE_ASSIGNMENT.link}
                element={<AssignmentPage />}
            />
            <Route
                path={ROUTES.TEACHER_ASSIGNMENT_PROBLEM.link}
                element={<AssignmentPage />}
            />
            <Route
                path={ROUTES.TEACHER_SCORE.link}
                element={<AssignmentPage />}
            />
            <Route
                path={ROUTES.CALLBACK.link}
                element={<CallbackPage />}
            />
        </Routes>
    </BrowserRouter>
);
