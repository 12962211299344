import { useNavigate } from 'react-router-dom';

import { Tabs } from 'antd';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { LoginRegisterTemplate } from '../../../template/pages/with-hocs';
import { LoginContainer, RegisterContainer } from '../containers';

const FormCard = styled(Card)(() => ({
    height: '500px',
    width: '400px',
}));

const tabItems = [
    {
        key: 'login',
        label: <Typography variant="h6">登入</Typography>,
        children: <LoginContainer />,
    },
    {
        key: 'register',
        label: <Typography variant="h6">註冊</Typography>,
        children: <RegisterContainer />,
    },
];

export const LoginRegisterPage = ({ type }) => {
    const navigate = useNavigate();
    const onTabChange = (value) => {
        navigate(`/${value}`);
    };

    const content = (
        <Grid container marginTop="60px">
            <Grid item xs={1} />
            <Grid item xs={5} marginTop="20px">
                <Box textAlign="center" typography="h1" padding={2}>
                    <Box>
                        Smart
                    </Box>
                    <Box marginLeft="1.5em">
                        Coding
                    </Box>
                    <Box>
                        Tutor
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={6} paddingLeft="80px">
                <Box sx={{ width: '400px', boxShadow: 5, borderRadius: '10px' }}>
                    <FormCard sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CardContent sx={{ height: '100%' }}>
                            <Tabs
                                activeKey={type}
                                items={tabItems}
                                onChange={onTabChange}
                                centered
                            />
                        </CardContent>
                    </FormCard>
                </Box>
            </Grid>
        </Grid>
    );

    return (
        <LoginRegisterTemplate content={content} />
    );
};
