import { useNavigate } from 'react-router-dom';

import { Table } from 'antd';

import { TagFactory } from '../../../components/tag/factories';
import ROUTES from '../../../router/routes';
import { useGetUserRecordsQuery } from '../../../store/services/restApi';
import { DateFormatter } from '../../../utils/formatter';
import { RECORD_TYPES, RECORD_TYPES_ZH } from '../constants';

const EXECUTION_TYPES = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

const columns = [
    {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        width: '5%',
        align: 'center',
    },
    {
        title: '類型',
        dataIndex: 'type',
        key: 'type',
        width: '10%',
        align: 'center',
        render: (type) => (RECORD_TYPES_ZH[type] || type),
    },
    {
        title: '結果',
        dataIndex: 'result',
        key: 'result',
        width: '20%',
        align: 'center',
        render: (result) => (
            result.type === RECORD_TYPES.SUBMIT
                ? TagFactory.get(result.status)
                : TagFactory.get(result.success)
        ),
    },
    {
        title: '程式語言',
        dataIndex: 'language',
        key: 'language',
        width: '20%',
        align: 'center',
    },
    {
        title: '測試時間',
        dataIndex: 'timestamp',
        key: 'timestamp',
        width: '30%',
        align: 'center',
        render: (timestamp) => DateFormatter.datetime(timestamp),
    },
];

export const RecordListContainer = ({ id, courseId }) => {
    const navigate = useNavigate();

    const {
        data: userRecords = [],
    } = useGetUserRecordsQuery({ problemId: id });

    const recordTableData = userRecords
        .map((record) => ({
            ...record,
            type: record.isSubmit ? RECORD_TYPES.SUBMIT : RECORD_TYPES.TEST,
            result: {
                type: record.isSubmit ? RECORD_TYPES.SUBMIT : RECORD_TYPES.TEST,
                success: record.success ? EXECUTION_TYPES.SUCCESS : EXECUTION_TYPES.ERROR,
                status: record.status,
            },
            key: record.id,
        })).sort((a, b) => {
            const dateA = new Date(a.timestamp);
            const dateB = new Date(b.timestamp);
            return dateB - dateA;
        });

    return (
        <Table
            columns={columns}
            dataSource={recordTableData}
            onRow={(record) => ({
                onClick: () => {
                    const url = courseId
                        ? `${ROUTES.COURSES.link}/${courseId}/problems/${id}/record/report/${record.id}`
                        : `${ROUTES.PROBLEMS.link}/${id}/record/report/${record.id}`;
                    navigate(url);
                },
                style: { cursor: 'pointer' },
            })}
        />
    );
};
