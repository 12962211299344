import { useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Divider, Form } from 'antd';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { OpenEduLoginButton } from '../../../components/button/OpenEduLoginButton';
import { Input } from '../../../components/input';
import { useBreadcrumb } from '../../../components/layout/BreadcrumbContext';
import { REQUEST_STATUS } from '../../../constants';
import ROUTES from '../../../router/routes';
import {
    login,
    selectLoginMessage,
    selectLoginStatus,
} from '../../../store/slices/authSlice';

export const LoginContainer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const status = useSelector(selectLoginStatus);
    const message = useSelector(selectLoginMessage);

    const loading = status === REQUEST_STATUS.LOADING;
    const showMessage = status === REQUEST_STATUS.ERROR;

    useEffect(() => {
        if (status === REQUEST_STATUS.SUCCESS) {
            navigate(ROUTES.HOME.link);
        }
    }, [status]);

    const handleLogin = ({ username, password }) => {
        dispatch(login({ username, password }));
    };

    const { setBreadcrumbs } = useBreadcrumb();

    useEffect(() => {
        setBreadcrumbs([
            {
                title: '登入',
                path: '/login',
            },
        ]);
    }, []);

    return (
        <Form onFinish={handleLogin}>
            <Form.Item name="username">
                <Input label="使用者帳號" required />
            </Form.Item>
            <Form.Item name="password">
                <Input label="密碼" password required />
            </Form.Item>
            {
                showMessage
                    ? (
                        <Box margin="16px 0" flex={1}>
                            <Alert severity="error">{message}</Alert>
                        </Box>
                    )
                    : <Box flex={1} />
            }
            <Box textAlign="center" flex={1}>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    type="submit"
                >
                    <Typography>登入</Typography>
                </LoadingButton>
            </Box>
            <Divider />
            <Box textAlign="center" margin="16px 0">
                <OpenEduLoginButton
                    color="primary"
                />
            </Box>
        </Form>
    );
};
