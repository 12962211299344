import MDEditor from '@uiw/react-md-editor';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import Box from '@mui/material/Box';

import { Spinner } from '../../../components/spinner';
import { useGetArticleByIdQuery } from '../../../store/services/restApi';

export const ArticleContainer = ({ id, courseId }) => {
    const navigate = useNavigate();
    const { problemId } = useParams();

    const {
        data: article = {},
    } = useGetArticleByIdQuery({ articleId: id });

    if (Object.keys(article).length === 0) {
        return (
            <Box
                textAlign="center"
                marginTop="24px"
            >
                <Spinner size={80} />
            </Box>
        );
    }

    return (
        <Box
            padding="24px"
        >
            <Box
                display="flex"
                justifyContent="right"
            >
                <Button
                    icon={<CloseCircleOutlined />}
                    onClick={() => {
                        navigate(`${courseId ? `/courses/${courseId}/problems/${problemId}/discussion` : `/problems/${problemId}/discussion`}`);
                    }}
                />
            </Box>
            <MDEditor.Markdown source={article?.content} />
        </Box>
    );
};
