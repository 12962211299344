import BaseTemplate from './BaseTemplate';
import LoginRegisterTemplate from './LoginRegisterTemplate';
import NoFooterTemplate from './NoFooterTemplate';
import SiderNoFooterTemplate from './SiderNoFooterTemplate';
import TeacherAdminTemplate from './TeacherAdminTemplate';

export {
    BaseTemplate,
    LoginRegisterTemplate,
    NoFooterTemplate,
    SiderNoFooterTemplate,
    TeacherAdminTemplate,
};
