import { Content } from '../../../components/layout';
import { basePalette } from '../../../theme/baseTheme';
import {
    FooterContainer,
    HeaderContainer,
} from '../../containers';

export const LoginRegisterTemplate = ({ content }) => (
    <>
        <HeaderContainer />
        <Content
            sx={{
                minHeight: 'calc(100vh - 64px)',
                background: `linear-gradient(35deg, ${basePalette.tan_0} 55%, ${basePalette.primary_light} 45%)`,
            }}
        >
            {content}
        </Content>
        <FooterContainer />
    </>
);
